body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type='file'] {
  width: 100%;
}
.CalendarMonth_table {
  width: 100%;
}

.flexBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #000000;
  border-left: 0.3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 24px;
}

.star:before,
.star:after {
  content: '';

  display: block;
  width: 0;
  height: 0;

  position: absolute;
  top: 0.6em;
  left: -1em;

  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #000000;
  border-left: 1em solid transparent;

  transform: rotate(-35deg);
}

.star:after {
  transform: rotate(35deg);
}

.bigger {
  font-size: 28px;
  font-weight: bold;
}

.alignCenter {
  text-align: center;
}

.small {
  font-size: 16px;
  font-weight: normal;
}

.textRed {
  color: red;
}

.textGreen {
  color: green;
}

.textBlack {
  color: black;
}

.prettyToast.Toastify__toast--success {
  background: #5cb85c;
}

.Toastify__toast-container.Toastify__toast-container--top-center {
  width: 720px;
  margin-left: -360px;
}

.prettyToast .Toastify__toast-body {
  font-size: 18px;
  padding: 14px;
}

.prodLineCheckpointConfirm .confirmTitle {
  line-height: 150%;
  font-weight: 700;
  margin-bottom: 30px;
  padding: 0 0 30px 0;
  border-bottom: 1px solid #d2d2d2;
}

.prodLineCheckpointConfirm h3 {
  padding-left: 40px;
}

.prodLineCheckpointConfirm button {
  padding: 10px 28px;
  font-size: 18px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 24px;
  text-align: center;
  gap: 8px;
}

.inputLabel {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.react-date-picker__calendar {
  z-index: 30;
}

.inputActionButton {
  text-decoration: underline;
  font-size: 12px;
  color: #007bff;
  cursor: pointer;
  opacity: 0.7;
  margin-top: 8px;
  font-weight: 700;
}

.inputActionButton:hover {
  opacity: 1;
}

.inputActionButton.disabled {
  opacity: 0;
  visibility: hidden;
}

/* classes duplicated in `@media print` object to correct apply colors on printing */
@media print {
  .textRed {
    color: red !important;
  }

  .textGreen {
    color: green !important;
  }

  .textBlack {
    color: black !important;
  }
}

.button {
  border: none;
  color: white;
  font-size: 18px;
  padding: 8px 20px;
  background: #235a98;
  text-transform: uppercase;
}

.button[disabled] {
  background: #acacac;
  color: #d1d1d1
}

.button[disabled]:hover {
  color: #d1d1d1;
}

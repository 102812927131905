.packingSlip thead {
  border-bottom: 2px solid;
  border-top: 1px solid #ddd;
}

.packingSlip .table {
  font-size: 18px;
}

.packingSlip tbody tr:nth-of-type(odd) > td,
td[rowspan] {
  background: #f3f3f3 !important;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.packingSlip .table > tbody + tbody {
  border-top: 4px solid black;
}

/* Dashed black border */
hr.dotted {
  border-top: 1px dashed black;
}

.packingSlip .table tr td {
  padding: 5px 16px;
  font-size: 20px;
  white-space: nowrap;
}

.packingSlip .table tr td.zoneCell,
.packingSlip .table tr td.bayCell {
  background: #ffffff !important;
  padding: 4px 52px;
}

.packingSlip .table tr td.nameCell {
  padding: 4px 16px;
  font-size: 20px;
}

.packingSlip .table tr td.quantityCell {
  padding: 4px 52px 4px 16px;
  font-size: 20px;
}

.packingSlip .table tr td.skuIdCell {
  padding: 4px 32px 4px 16px;
  font-size: 24px;
  font-weight: bold;
}

.packingSlip.tooBig .table tr td {
  font-size: 16px;
}

.packingSlip.tooBig .table tr td.nameCell {
  font-size: 16px;
}

.packingSlip.tooBig .table tr td.quantityCell {
  font-size: 16px;
}

.packingSlip.tooBig .table tr td.skuIdCell {
  font-size: 20px;
}
